import React, { useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroGradient from "../components/General/HeroGradient";
import Intro from "../components/General/Intro";
import Projects from "../components/Projects/Projects";
import CTA from "../components/General/CTA";
import Footer from "../components/Context/Footer";
import GatsbyImage from "gatsby-image";
import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const ProjectListing = ({ data:staticData, pageContext, location }) => {
    const data = usePreviewData(staticData);
    const {
        name,
        tagline,
        intro,
        heading_promo,
        teaser,
        button_cta,
        link_promo,
        meta_description,
        share_image
    } = data.prismicProjectListing.data;
    const projects = data.allPrismicProject.nodes.map(project => {
        return {
            url: project.url,
            image: <GatsbyImage fluid={project.data.listing_image.fluid} alt={project.data.listing_image.alt} />,
            description: project.data.summary.html,
            type: project.data.type,
            title: project.data.name.text,
            subtitle: project.data.tagline
        }
    });

    useEffect(() => {
        let timer;
        if(window && location.state && location.state.fromPagination && location.action === "PUSH") {
            timer = setTimeout(() => {
                let content = document.querySelector('#content')
                if(content) {
                    window.scrollTo(0, content.offsetTop - 200);
                }
            }, 350)

        }
        return () => {
            clearTimeout(timer);
        }

    }, [location]);


        const theme = "light";
    return (
        <Layout>
            <SEO title={name.text} description={meta_description} image={share_image.url} />
            <Header theme={theme} />
            <HeroGradient
                title={name.text}
                subtitle={tagline.html}
                theme={theme}
            />
            <Intro
                content={intro.html}
                theme={theme}
            />
            <span id={"content"} />
            <Projects
                theme={theme}
                projects={projects}
                pagination={{
                    currentPage: pageContext.currentPage,
                    numPages: pageContext.numPages,
                    baseUrl: "/projects/"
                }}
            />
            <CTA
                theme={theme}
                title={heading_promo.text}
                content={teaser.html}
                buttonText={button_cta}
                buttonUrl={link_promo.url}
                buttonInternal={true}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query getProjectsQuery($skip: Int!, $limit: Int!) {
        allPrismicProject(sort: {fields: first_publication_date, order: DESC}, limit: $limit, skip: $skip) {
            nodes {
                uid
                url
                data {
                    listing_image {
                        alt
                        fluid(maxWidth: 974) {
                            ...GatsbyPrismicImageFluid
                        }
                        thumbnails {
                            Mobile {
                                fluid {
                                    ...GatsbyPrismicImageFluid
                                }
                            }
                        }
                    }
                    name {
                        text
                    }
                    project_link {
                        url
                        target
                        link_type
                    }
                    summary {
                        html
                    }
                    type
                    tagline
                }
            }
        }
        prismicProjectListing {
            data {
                name {
                    text
                }
                tagline {
                    html
                }
                intro {
                    html
                }
                heading_promo {
                    text
                }
                teaser {
                    html
                }
                button_cta
                link_promo {
                    url
                    target
                    link_type
                }
                meta_description
                share_image {
                    url
                }
            }
        }

    }
`;

export default ProjectListing;
