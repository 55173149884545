import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import { Link } from "gatsby";

const Project = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
    padding-bottom: 56px;
    align-items: stretch;
    border-bottom: 1px solid ${color("grey")};

    &:last-of-type{
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }

    @media screen and (min-width: 768px) {
        flex-direction: column;
        margin-bottom: 64px;
        padding-bottom: 64px;
        padding-left: 11px;
        padding-right: 11px;
        width: 50%;

        &:nth-last-of-type(2){
            &:nth-of-type(2n+1){
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
        padding-bottom: 64px;
        width: 33.3333%;

        &:nth-last-of-type(2){
            &:nth-of-type(2n+1){
                margin-bottom: 64px;
                padding-bottom: 64px;
                border-bottom: 1px solid ${color("grey")};
            }
        }

        &:nth-last-of-type(2){
            &:nth-of-type(3n+2), &:nth-of-type(3n+1){
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        &:nth-last-of-type(3){
            &:nth-of-type(3n+1){
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
        padding-bottom: 72px;

        &:nth-last-of-type(2){
            &:nth-of-type(2n+1){
                margin-bottom: 72px;
                padding-bottom: 72px;
            }
        }

        &:nth-last-of-type(2){
            &:nth-of-type(3n+2), &:nth-of-type(3n+1){
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        &:nth-last-of-type(3){
            &:nth-of-type(3n+1){
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
`;
const Type = styled.p`
    ${typography.label};
    text-transform: uppercase;
    margin-bottom: 8px;
    position: relative;
    color: ${color("dark")};

    @media screen and (min-width: 768px) {
        margin-bottom: 24px;
    }
`;
const ProjectTitle = styled.h3`
    ${typography.p1};
    margin-bottom: 16px;

    strong{
        font-weight: 600;
    }

    a{
        color: ${color("dark")};
        text-decoration: none;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 32px;
    }
`;
const ProjectDescription = styled.div`
    p{
        ${typography.body1};
    }

    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
        flex-grow: 1;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 48px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 64px;
    }
`;
const ProjectImage = styled.div`
    width: 100%;
`;
const ProjectImageContainer = styled.div`
    width: 100%;
    padding-top: 73.1%;
    position: relative;
    height: 100%;
    overflow: hidden;

    a{
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        span{
            display: inline-block;
            height: 100%;
            width: 100%;
        }
    }

    picture, img, .gatsby-image-wrapper{
        display: block;
        height: 100%;
        width: 100%;
    }

    img{
        object-position: center center;
        object-fit: cover;
    }

    @media screen and (min-width: 768px) {
        padding-top: 86%;
    }
`;
const ProjectLink = styled(Link)`
    ${typography.link};
    display: inline-block;
    text-decoration: underline;
    color: ${color("dark")};
    
    &:hover{
        color: ${color("dark blue")};
    }
`;
const ProjectDetails = styled.div`
    text-decoration: none;
    color: ${color("dark")};
    transition: color 0.2s ease;
    padding-top: 40px;
    background-color: ${color("white")};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 768px) {
        padding-top: 26px;
        flex-grow: 1;
        padding-right: 10px;
    }
`;

const ProjectComponent = ({image, type, title, subtitle, description, url, linkText}) => {
    return (
        <Project>
            <ProjectImage>
                <ProjectImageContainer>
                    <Link to={url}>
                        {image}
                    </Link>
                </ProjectImageContainer>
            </ProjectImage>
            <ProjectDetails>
                <Type>{type}</Type>
                <ProjectTitle>
                    <Link to={url}>
                        <strong>{title}</strong> – {subtitle}
                    </Link>
                </ProjectTitle>
                <ProjectDescription dangerouslySetInnerHTML={{ __html: description }}/>
                <ProjectLink to={url}>
                    {linkText}
                </ProjectLink>
            </ProjectDetails>
        </Project>
    );
};

export default ProjectComponent;
