import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Project from "./Project";
import Container from "../Context/Container";
import Section from "../Context/Section";
import Pagination from "../Elements/Pagination";

const ProjectsWrapper = styled.div`
    width: 100%;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -11px;
        margin-right: -11px;
    }

    ${props => props.hasPagination ? css`
        margin-bottom: 48px;

        @media screen and (min-width: 1200px) {
            margin-bottom: 64px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 72px;
        }

        @media screen and (min-width: 2200px) {
            margin-bottom: 100px;
        }
    ` : null}
`;

const PaginationWrapper = styled.div`
    @media screen and (min-width: 768px) {
        text-align: right;
    }
`;

const PaginationContainer = styled(Container)`
    padding: 0;

    @media screen and (min-width: 768px) {
        padding: 0;
    }
`;

const Projects = ({projects, pagination, theme}) => {

    const ProjectsList = projects.map(project => {
        return (
            <Project
                title={project.title}
                subtitle={project.subtitle}
                image={project.image}
                description={project.description}
                type={project.type}
                linkText="View this project"
                url={project.url}
                key={project.title}
            />
        )
    });

    return (
        <Section paddingS="0 0 64px" paddingM="0 0 72px" paddingL="0 0 105px" paddingXL="0 0 120px" paddingXXL="0 0 145px" bg="white" fg="dark" hr="grey" theme={theme}>
            <Container>
                <ProjectsWrapper hasPagination={pagination && pagination.numPages > 1}>
                    {ProjectsList}
                </ProjectsWrapper>
                {pagination && pagination.numPages > 1 ? (
                    <PaginationContainer>
                        <PaginationWrapper>
                            <Pagination currentPage={pagination.currentPage} numPages={pagination.numPages} baseUrl={pagination.baseUrl} />
                        </PaginationWrapper>
                    </PaginationContainer>
                ) : null}
            </Container>
        </Section>
    );
};

export default Projects;
